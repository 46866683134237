<template>
    <a-layout id="guest-onboarding-layout-create-account">
        <a-layout-header class="guest-onboarding-header">
            <div class="logo">
                <img src="/partners/sims-logo-new.png" alt="Logo" />
            </div>
            <div class="right">
                <a-button style="margin-right: 0px;" @click.prevent="backToDemo" size="large" type="link">
                    <a-icon type="video-camera"></a-icon>
                    Watch Demo</a-button>

                <a-button style="margin-right: 15px;" @click.prevent="backToPricing" size="large" type="link">
                    <a-icon type="dollar"></a-icon>
                    View Pricing</a-button>

                <a-button class="btn-rounded" size="large" @click.prevent="goBack" type="primary">&larr; Go Back</a-button>
            </div>
        </a-layout-header>
        <a-layout-content :style="{ background: 'rgb(247, 248, 250)', padding: '30px', overflow: 'auto' }">

            <loading-screen :is-loading="isSaving"></loading-screen>

            <div class="inner">
                <a-row>
                    <a-col :span="3"></a-col>
                    <a-col :span="18" class="welcome-col">
                        <h1 class="intro">Step 1: Create Your Account</h1>

                        <p class="intro-message">
                            <!-- Please only continue if you are capable of inviting all other users in your organisation. -->
                            To get started, it's important that your user account is set up as an administrator. As an
                            administrator, you'll have the necessary permissions and access to create and assign user
                            permissions, as well as map users to response groups. To ensure a smooth onboarding experience
                            for your organisation, please only proceed with filling out this form if you're able to invite
                            colleagues and assign permisisons.
                        </p>

                        <!-- <p class="intro-message-2">
                            To ensure a smooth onboarding experience for your organisation, please proceed with filling out this form if you're able to invite colleagues, assign permissions, and create and map users to response groups.
                        </p> -->
                    </a-col>
                </a-row>

                <a-row class="form-row" :gutter="60">
                    <a-col :span="6"></a-col>
                    <a-col :span="12">

                        <!-- <a-row :gutter="30">
                            <a-col :span="12">
                                <a-form-item label="First Name">
                                    <a-input size="large" v-model="form.first_name"></a-input>
                                </a-form-item>
                            </a-col>
                            <a-col :span="12">
                                <a-form-item label="Last Name">
                                    <a-input size="large" v-model="form.last_name"></a-input>
                                </a-form-item>
                            </a-col>
                        </a-row> -->

                        <a-form-item label="Email">
                            <a-input size="large" v-model="form.email"></a-input>
                        </a-form-item>
                        <!-- 
                        <a-form-item label="Password">
                            <a-input size="large" v-model="form.password" type="password"></a-input>
                        </a-form-item> -->

                        <a-button size="large" @click.prevent="attemptCreateAccount" class="create-account-btn"
                            to="/guest-onboarding/create-org" type="primary">Create
                            Account</a-button>

                    </a-col>
                </a-row>

            </div>

        </a-layout-content>
    </a-layout>
</template>

<script>
import LoadingScreen from '../../components/LoadingScreen.vue';
import onboarding from '../../api/onboarding';
import posthog from 'posthog-js';

export default {
    data() {
        return {
            isSaving: false,
            form: {
                email: ''
            }
        }
    },

    created() {
        // If posthog initialised
        if (window.POSTHOG_INITIALISED) {
            posthog.capture('onboarding_create_account');
        }

        if (this.$oidc.isAuthenticated) {
            this.$router.push('/guest-onboarding/create-org');
        }
    },

    components: { LoadingScreen },

    methods: {
        goBack() {
            this.$router.push({
                path: '/guest-onboarding',
                query: this.$route.query
            });
        },

        backToDemo() {
            this.$router.push({
                path: '/guest-onboarding',
                query: {
                    ...this.$route.query,
                    section: 'demo'
                }
            }); 
        },

        backToPricing() {
            this.$router.push({
                path: '/guest-onboarding',
                query: {
                    ...this.$route.query,
                    section: 'pricing'
                }
            }); 
        },

        validate() {
            if (!this.form.email || this.form.email.trim().length == 0) {
                this.$message.error('Please provide an email address.');
                return false;
            }

            return true;
        },

        attemptCreateAccount() {
            if (!this.validate()) {
                return false;
            }

            let vm = this;
            vm.isSaving = true;
            onboarding.createUser({
                email: vm.form.email
            }).then(() => {
                vm.isSaving = false;
                vm.$router.push('/guest-onboarding/create-org');
            }).catch(e => {
                // Check if error code is 409
                if (e.response.status === 409) {
                    vm.$message.info('An account with that email address already exists. Logging you in...');
                    vm.isSaving = false;
                    vm.$router.push('/guest-onboarding/create-org');
                    return;
                }

                console.log(e);
                vm.isSaving = false;
                vm.$message.error('There was an error creating your account. Please try again.');
            });
        }
    }
}
</script>

<style lang="scss">
#guest-onboarding-layout-create-account {
    // min-height: 100vh;
    // background: #3A0BA3;
    background: rgb(247, 248, 250);
    overflow-y: scroll !important;
    height: 100vh;
    max-height: 100vh;

    .welcome-col {
        text-align: center;
    }

    .guest-onboarding-header {
        background: #3A0BA3;
        text-align: center;

        padding-left: 20px;
        padding-right: 20px;

        height: 65px;
        line-height: 65px;

        .logo {
            height: 40px;
            float: left;

            img {
                max-height: 100%;
            }
        }

        .right {
            float: right;

            .ant-btn-link {
                color: #fff;
            }
        }
    }


    .intro {
        text-align: center;
        font-size: 2.3em;
        // margin-bottom: 40px;
        // color: #fff;
        font-family: 'Quicksand';
        font-weight: 500;
    }

    .intro-message {
        padding-bottom: 10px;
    }

    .intro-message-2 {
        padding-bottom: 10px;
    }

    .ant-input {
        // background: rgba(255, 255, 255, 0.2);
        // border: none;
        // color: #fff;
        padding: 25px 20px;
        // border-radius: 25px;
    }

    .ant-form-item-label>label {
        // color: #fff !important;
        font-family: 'Quicksand';
        font-weight: 500 !important;
        font-size: 1.1em !important;
    }

    .create-account-btn {
        display: block;
        text-align: center;
        margin-top: 50px;
        // color: #fff;
        // padding: 15px 40px;
        border-radius: 25px;
        font-family: 'Quicksand';
        // border: 2px solid #fff;
        font-weight: 500;
        // transition: all 0.2s ease-in-out;
        // margin-left: 10px;
        // margin-right: 10px;
        width: 100%;

        // &:hover {
        //     color: #3A0BA3;
        //     background: #fff;
        // }
    }

    .inner {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 30px;

        ul {
            padding-left: 17px;
        }

        p,
        li {
            font-size: 16px;
            // color: #fff;
            font-family: 'Quicksand';
        }

        ul li+li {
            margin-top: 5px;
        }
    }
}
</style>